import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertController, LoadingController, NavController, ToastController } from '@ionic/angular';
import * as Leaflet from 'leaflet';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-geocercas',
  templateUrl: './geocercas.page.html',
  styleUrls: ['./geocercas.page.scss'],
})
export class GeocercasPage implements OnInit {

  map: Leaflet.Map;
  lat: number = 21.9934775;
  lng: number = -99.0176878;
  linea:any;
  lineas:any = [];
  punto:any;
  radio:number = 10;
  intCantLados:number = 32;
  centro:any = {};
  circle:any;
  id:any;
  objeto:any = {};
  radius:number = 200;
  nombre:string = "";
  entrar:boolean = false;
  salir:boolean = false;
  usuario:any = null;

  constructor( public toastController:ToastController, public alertController: AlertController, public route: ActivatedRoute,
              public _api:ApiService, public loadingCtrl:LoadingController, public navCtrl:NavController ) { 
    this.route.params.subscribe((params)=>{
      console.log(params);
      this.id = params.id;
      this._api.getObjeto({imei:this.id}).subscribe((data:any)=>{
        console.log("objeto", data);
        this.objeto = data.data[0];
        this.lat = this.objeto.lat;
        this.lng = this.objeto.lng;        
      });
    });
  }

  ngOnInit() {
    this.centro = { lat:this.lat, lng:this.lng };
    setTimeout(()=>{
      this.leafletMap();
      this.addMrcas(this.objeto);
    }, 500);
    this.presentToastTwo();
    this.usuario = localStorage.getItem("user");
  }

  addMrcas( dataUbic:any ){
    let iconGps:any;
    let marker:any;    
    
    if( dataUbic.speed <= 4 &&  dataUbic.acc == 1  ){

      iconGps = Leaflet.icon({
          iconUrl: this.validaUrl( dataUbic.url_icon,'../../../assets/images/marca-amarilla.png'),
          iconSize: this.iconSize( dataUbic ),//[28, 41],
          iconAnchor: this.iconAnchor( dataUbic ),//[14, 20],
          popupAnchor: [-1, -30]
      });
    }else if(  dataUbic.speed <= 4 &&  dataUbic.acc == 0  ){

      iconGps = Leaflet.icon({
          iconUrl: this.validaUrl( dataUbic.url_icon,'../../../assets/images/marca-roja.png'),
          iconSize: this.iconSize( dataUbic ),//[28, 41],
          iconAnchor: this.iconAnchor( dataUbic ),//[14, 20],
          popupAnchor: [-1, -30]
      });
    }else if(  dataUbic.speed > 4 &&  dataUbic.acc == 1 ){
      iconGps = Leaflet.icon({
          iconUrl: this.validaUrl( dataUbic.url_icon,'../../../assets/images/marca-verde.png'),
          iconSize: this.iconSize( dataUbic ),//[28, 41],
          iconAnchor: this.iconAnchor( dataUbic ),//[14, 20],
          popupAnchor: [-1, -30]
      });
    }

    if( iconGps ){
      console.log("recibe encendido");
      marker = new Leaflet.marker([this.number(dataUbic.lat), this.number(dataUbic.lng)], {icon:iconGps, rotationAngle:this.validaAngulo(dataUbic.url_icon_angulo,dataUbic.angulo) })                       
    }else{
      /*Por si no recibe encendido hacerlo con velicudad*/
      if( dataUbic.speed > 4 ){
        console.log("NO encendido");
        iconGps = Leaflet.icon({
            iconUrl: this.validaUrl( dataUbic.url_icon, '../../../images/assets/marca-verde.png'),
            iconSize: this.iconSize( dataUbic ),
            iconAnchor: this.iconAnchor( dataUbic ),
            popupAnchor: [-1, -30]
        });
      
        marker = new Leaflet.marker([this.number(dataUbic.lat), this.number(dataUbic.lng)], {icon:iconGps, rotationAngle:this.validaAngulo(dataUbic.url_icon_angulo,dataUbic.angulo) })
                          // .bindPopup(`<strong>Nombre:</strong> ${dataUbic.nombre}<br><strong>Dirección:</strong>${dataUbic.direccion}
                          //             <br><strong>Cerca de:</strong>${dataUbic.pdi}<br><strong>Ubicación:</strong>
                          //             <a href="https://www.google.com.mx/maps?q=${this.number(dataUbic.lat)},${this.number(dataUbic.lng)}"  target="_blank">
                          //             ${dataUbic.lat},${dataUbic.lng}</a><br><strong>Velocidad:</strong>${this.speed(dataUbic.speed)}Km/h<br>
                          //             <strong>Status: </strong>${this.timeConversion( dataUbic.status_motor )} ${this.tipoStatus(dataUbic.speed, dataUbic.acc)}<br>
                          //             <strong>Motor:</strong>${this.motor(dataUbic.acc)}<br><strong>Fecha:</strong>${this.getTIMESTAMP( this.number(dataUbic.timestamp) )}<br><a href="javascript:;" onclick="mostrarStreetView(${dataUbic.lat},${dataUbic.lng},'${dataUbic.nombre}','${dataUbic.direccion}','${ this.speed( dataUbic.speed )}',${dataUbic.acc}, '${this.getTIMESTAMP( this.number(dataUbic.timestamp) )}')"><img src="../../../assets/images/sv-min.png" width="16px" />Vista Calle</a>`).addTo(this.map);

      }else{
        
        iconGps = Leaflet.icon({
            iconUrl: this.validaUrl( dataUbic.url_icon,'../../../assets/images/marca-roja.png'),
            iconSize: this.iconSize( dataUbic ),
            iconAnchor: this.iconAnchor( dataUbic ),
            popupAnchor: [-1, -30]
        });

        marker = new Leaflet.marker([this.number(dataUbic.lat), this.number(dataUbic.lng)], {icon:iconGps, rotationAngle:this.validaAngulo(dataUbic.url_icon_angulo,dataUbic.angulo) })                          
      }
    }

    this.map.setView([dataUbic.lat, dataUbic.lng]);
    marker.bindTooltip(dataUbic.nombre, 
    {
        permanent: true, 
        direction: 'right',
        offset: Leaflet.point({x: 20, y: 0})
    }).addTo(this.map);

  }

  validaAngulo( status:any, angulo:any ){    
    //console.log( status, angulo );
    if( status == undefined || status == 2 ){      
      return angulo;
    }

    if( status == 1 ){
      return angulo;
    }else{
      return 0;
    } 
  }

  iconAnchor( data:any ){

    if( data.url_icon == undefined || data.url_icon == "null" ){
      return [14, 20];
    }

    if( data.url_icon !== "null" ){
      if( data.url_icon_angulo == 0 ){
        let size = JSON.parse( atob( data.size_icon ) ); 
        return [ size.size_ancho/2, size.size_largo/2 ]
      }else{
        return [ 12.5, 25 ]
      }
    }else{
      return [14, 20];
    }
  }

  iconSize( data:any ){

    if( data.url_icon == undefined || data.url_icon == "null" ){
      return [28, 40];
    }
    //console.log(data.url_icon);
    if( data.url_icon !== "null" ){         
      if( data.url_icon_angulo == 0 ){
        let size = JSON.parse( atob( data.size_icon ) );        
        return [ size.size_ancho, size.size_largo ]
      }else{      
        return [ 25, 50 ]
      }
    }else{           
      return [28, 40];
    }
  }

  validaUrl( url_server:any, url:any ){    
    if( url_server !== "null" && url_server !== undefined ){
      return `${url_server}`;
    }else{
      return `${url}`;
    }
  }

  private number(value: any): number {
    if( value == "0" ){
      return 0;
    }else{
      return + value;
    }
  }

  leafletMap() {

    let osmUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
		let osmAttrib = '&copy; <a href="http://openstreetmap.org/copyright">OpenStreetMap</a> contributors';
		let osm  = Leaflet.tileLayer(osmUrl, { maxZoom: 19, attribution: osmAttrib });
    this.map = Leaflet.map('map-geocercas', { center: [this.lat, this.lng], zoom: 16 });

    const here = {
      id: '6AJleReU2wy5FIYdcHUZ',
      code: '2sYGPV-IeanNImtVlcmNpw'
    }
    const style = 'normal.day';

    Leaflet.control.layers({
      'OSM': osm.addTo(this.map),
      "Humanitarian": Leaflet.tileLayer('http://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', { attribution: 'Map &copy; <a href=\"http://openstreetmap.org\">OpenStreetMap</a> | Tiles &copy; <a href=\"http://hot.openstreetmap.org\">Humanitarian OSM Team</a>', minZoom: 0, maxZoom: 20 }),
      'HERE': Leaflet.tileLayer(`https://2.base.maps.api.here.com/maptile/2.1/maptile/newest/${style}/{z}/{x}/{y}/512/png8?app_id=${here.id}&app_code=${here.code}&ppi=320`,{
        attribution:"HERE",
        maxZoom:19
      }),
        'HERE HYBRID': Leaflet.tileLayer(`https://2.aerial.maps.api.here.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/512/png8?app_id=${here.id}&app_code=${here.code}`,{
        attribution: 'HERE hybrid',
        maxZoom: 19
      }),
      'Google Streets': Leaflet.tileLayer('http://www.google.com/maps/vt/lyrs=m&x={x}&y={y}&z={z}', {
	      attribution: 'google',
	      maxZoom: 20,
      }),           
      "Google Traffic": Leaflet.tileLayer('https://{s}.google.com/vt/lyrs=m@221097413,traffic&x={x}&y={y}&z={z}', {
          attribution: 'google',
          maxZoom: 20,
          minZoom: 2,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }),
      "Google Hybrid": Leaflet.tileLayer('https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}', {
          attribution: 'google',
          maxZoom: 20
      })  
    }).addTo(this.map);

    this.map.on("click", ( e:any )=>{
      
      console.log(e);
      this.punto = e.latlng;
      this.radio = Math.sqrt(Math.abs(Math.pow(this.punto.lat-this.centro.lat,2)+Math.pow(this.punto.lng-this.centro.lng,2)));
      // this.lineas = this.GetPoints(this.centro.lat,this.centro.lng, this.radio);
      this.dibujarPolilinea();
    });

  }

  GetPoints(Latitud:number, Longitud:number, Radio:number){
    let centerAng = 2 * Math.PI / this.intCantLados;
    let Puntos = [];
    for (let i = 0; i < this.intCantLados; i++)
    {
        let ang = i * centerAng;
        let x = Longitud + Radio * Math.cos(ang);
        let y = Latitud - Radio * Math.sin(ang);
        Puntos.push({"lat":y, "lng":x});
    }
    return Puntos;
  }

  async presentToastTwo() {
    const toast = await this.toastController.create({
      message: 'Selecciona el mapa para añadir una geocerca.',
      duration: 6000,
      cssClass: 'toast-two',
      color:"primary",
      position:'top',
      animated:true,
      icon: "alert-circle",
      buttons: [
        {
          text: 'Ok',
          role: 'cancel'
        }
      ],
    });

    await toast.present();
  }

  dibujarPolilinea(){
    if ( this.linea ) {
      this.linea.remove();
    }
    
    if( this.circle ){        
      this.circle.remove();
    }

    this.circle = Leaflet.circle(this.punto, {
      color: '#428bca',
      fillColor: '#f03',
      fillOpacity: 0.5,
      radius: this.radius
    }).addTo(this.map);

    // this.linea = Leaflet.polygon(this.lineas,{color: '#428bca',fillColor: '#428bca'}).addTo(this.map); 
  }

  async guardar(){
    // if( this.lineas.length == 0 ){
    //   this.presentToast("Selecciona el mapa para expander tu geocerca.");
    // }

    const loading = await this.loadingCtrl.create({
      message:"Guardando geocerca...",
      duration:2000
    });

    loading.present();
    
    if( !this.punto ){
      loading.dismiss();
      return this.presentToast("Dibuja una geocerca.", 'danger');
    } 

    if( this.nombre.length == 0 ){
      loading.dismiss();
      return this.presentToast("El nombre de la geocerca es requerido.", "danger");
    } 

    let dataGeocerca = { color:"#428bca", 
                         lat: this.punto.lat, 
                         lng: this.punto.lng, 
                         nombre: this.nombre, 
                         radio: this.radius, 
                         tipo:1, 
                         encode:"",
                         user: this.usuario
                        };

    console.log( dataGeocerca );

    this._api.addGeocercaPDIRutas( dataGeocerca ).subscribe((data:any)=>{
        if(  data.status == 1 ){
          this.navCtrl.back();
          this.presentAlert( "La geocerca se guardo correctamente." );
        }else{
          this.presentAlert( data.mensaje );
        }

    });

  }

  async presentAlert( mensaje:string ) {
    const alert = await this.alertController.create({     
      subHeader: 'Mensaje',
      message: mensaje,
      buttons: ['OK'],
    });

    await alert.present();
  }

  async presentToast(message:string, color:string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2500,
      color:color
    });
    toast.present();
  }

  cleanMapa(){
    if ( this.linea ) {
      this.linea.remove();
    }
    this.lineas = [];

    if( this.circle ){        
      this.circle.remove();
      this.punto = null;
    }
  }

  addObjetos(){
    this.presentAlertCheckbox();
  }

  onIonChange(event:any){
    console.log(event);
    // this.linea.remove();
    // this.circle.remove();
    console.log(event.detail.value);
    this.radius = event.detail.value;
    this.dibujarPolilinea();
    
  }

  async presentAlertCheckbox(){
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Checkbox',
      inputs: [
        {
          name: 'checkbox1',
          type: 'checkbox',
          label: 'Checkbox 1',
          value: 'value1',
          handler: () => {
            console.log('Checkbox 1 selected');
          },
          checked: true
        },

        {
          name: 'checkbox2',
          type: 'checkbox',
          label: 'Checkbox 2',
          value: 'value2',
          handler: () => {
            console.log('Checkbox 2 selected');
          }
        },

        {
          name: 'checkbox3',
          type: 'checkbox',
          label: 'Checkbox 3',
          value: 'value3',
          handler: () => {
            console.log('Checkbox 3 selected');
          }
        },

        {
          name: 'checkbox4',
          type: 'checkbox',
          label: 'Checkbox 4',
          value: 'value4',
          handler: () => {
            console.log('Checkbox 4 selected');
          }
        },

        {
          name: 'checkbox5',
          type: 'checkbox',
          label: 'Checkbox 5',
          value: 'value5',
          handler: () => {
            console.log('Checkbox 5 selected');
          }
        },

        {
          name: 'checkbox6',
          type: 'checkbox',
          label: 'Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6 Checkbox 6',
          value: 'value6',
          handler: () => {
            console.log('Checkbox 6 selected');
          }
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: () => {
            console.log('Confirm Ok');
          }
        }
      ]
    });

    await alert.present();
  }

}
